import React from 'react';


import RealTimeVerification from '../../../../common/assets/image/saasModern/HomePageImg/RealTimeImg.png';
import BeyondImg from '../../../../common/assets/image/saasModern/HomePageImg/ByondEmail.png';
import LeftBorder from '../../../../common/assets/image/saasModern/HomePageImg/LeftHrBorder.svg';
import RightBorder from '../../../../common/assets/image/saasModern/HomePageImg/RightSideHrBorder.svg';

import {
  BannerWrapper,
  ContainerWrapper,
  SubContainerWrapper,
} from './email.style';

const EmailVerificationContainer = () => {
  return (
    <BannerWrapper>
      <h2>Email Verification</h2>
      <p>
      Ensure Deliverability with Real-Time Email Verification & Reach the Right People’s Inboxes 
      </p>
      <ContainerWrapper>
        <div className="frame">
          <div className="div">
            <div className="div-2">
            <div className="div-5">
                  <div className="text-wrapper" >Real-Time Verification for Guaranteed Reach</div>
                </div>
              <div className="full-width">
                <div className="div-3">
                  <div className="group-wrapper">
                    <img
                      className="group"
                      alt="AutomatedSequance"
                      src={RealTimeVerification}
                    />
                  </div>
                </div>
              </div>
              <div className="div-4">
                
                <p className="p">
                Complement your sales engagement efforts with our built-in email verification system. Each email address you unlock undergoes real-time verification, ensuring <b>Boosted Deliverability, Reduced Costs.</b> 
                </p>
              </div>
            </div>
            <div className="div-2">
            <div className="div-5">
                 
                 <div className="text-wrapper">Beyond Verification: Understanding Catch-All Emails</div>
               </div>
              <div className="overlap-wrapper">
                <div className="overlap">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group">
                      <img className="img" alt="Group" src={BeyondImg} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-4">
                {/* <div className="div-5">
                 
                  <div className="text-wrapper"style={{marginLeft:'5px'}}>Beyond Verification: Understanding Catch-All Emails</div>
                </div> */}
                <p className="p">
                  <ul>
                    <li style={{marginLeft:'14px'}}>Catch-All Email: Captures all emails sent to a specific domain, regardless of the specific username (e.g., [email address removed]).</li>
                    <li style={{marginLeft:'14px'}}>Non-Catch-All Email: Requires an exact email address to be delivered to a specific user (e.g., [email address removed]).</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </ContainerWrapper>
    </BannerWrapper>
  );
};
export default EmailVerificationContainer;
