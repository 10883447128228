import React from "react";
import DataEnrichmentCSV from "../../../../common/assets/image/saasModern/HomePageImg/DataEnrichmentCSV.png";
import CRMCLeanUp from "../../../../common/assets/image/saasModern/HomePageImg/CRMENrichment.png";
import WebsiteEnrichment from "../../../../common/assets/image/saasModern/HomePageImg/WebsiteEnrichment.png";
import RightArrowIcon from "../../../../common/assets/image/saasModern/HomePageImg/ArrowRight.svg";
import { Link } from "gatsby";
import {
  BannerWrapper,
  ContainerWrapper,
  VerticalLine,
} from "./dataEnrichment.style";

const DataEnrichmentContainer = () => {
  return (
    <BannerWrapper>
      <h2>Data Enrichment</h2>
      <p>
        Transform Incomplete Data into Actionable Insights. Unlock the <br />
        Power of Complete and Enriched Data
      </p>
      <ContainerWrapper>
        <main>
          <div className='box'>
            <div className='img-container'>
              <img src={DataEnrichmentCSV} alt='Data Enrichment CSV' />
            </div>
            <div className='heading-text'>CSV Enrichment</div>
            <div className='paragraph-text'>
              Enhance existing contact lists by <b>uploading a CSV file</b> and
              matching them against our comprehensive database, adding valuable
              information like{" "}
              <b>
                phone numbers, job titles, company details, 40+ other data
                attributes
              </b>
              .
            </div>
          </div>
          <VerticalLine />
          <div className='box'>
            <div className='img-container'>
              <img src={CRMCLeanUp} alt='CRM Enrichment' />
            </div>
            <div className='heading-text'>CRM Enrichment</div>
            <div className='paragraph-text'>
              <b>Synchronize your existing CRM data</b> with our platform,
              automatically <b>enriching your CRM records</b> with additional
              insights and keeping your data fresh and up-to-date.
            </div>
          </div>
          <VerticalLine />
          <div className='box'>
            <div className='img-container'>
              <img src={WebsiteEnrichment} alt='Website Form Enrichment API' />
            </div>
            <div className='heading-text'>Website Form Enrichment API</div>
            <div className='paragraph-text'>
              Leverage our API to <b>enrich website form submissions</b> in
              real-time, capturing valuable 40+ data points and enhancing your
              lead qualification process.
              <Link href='/website-form-enrichment-apis/'>
                <button className='btn'>
                  Learn More
                  <img src={RightArrowIcon} />
                </button>
              </Link>
            </div>
          </div>
        </main>
      </ContainerWrapper>
    </BannerWrapper>
  );
};

export default DataEnrichmentContainer;
