import React, { useEffect, useRef } from 'react';
import './style.css';
import logo1 from '../../../common/assets/image/saasModern/HomePageImg/aajTak.png';
import logo2 from '../../../common/assets/image/saasModern/HomePageImg/monster.png';
import logo3 from '../../../common/assets/image/saasModern/HomePageImg/hexaware.png';
import logo4 from '../../../common/assets/image/saasModern/HomePageImg/ness.png';
import logo5 from '../../../common/assets/image/saasModern/HomePageImg/NoBroker.png';
import logo6 from '../../../common/assets/image/saasModern/HomePageImg/Quess.png';
import logo7 from '../../../common/assets/image/saasModern/HomePageImg/3iInfotech.png';
import logo8 from '../../../common/assets/image/saasModern/HomePageImg/HCL.png';
import logo9 from '../../../common/assets/image/saasModern/HomePageImg/Wipro.png';
import logo10 from '../../../common/assets/image/saasModern/HomePageImg/Bosch.png';
import logo11 from '../../../common/assets/image/saasModern/HomePageImg/Sms.png';

const LogoSliderLeftToRight = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollAmount = container.scrollWidth - container.clientWidth;
    const slide = () => {
      scrollAmount -= 2;
      container.scrollTo(scrollAmount, 0);
      if (scrollAmount <= 0) {
        // Reset scroll position to end when it reaches the start
        scrollAmount = container.scrollWidth - container.clientWidth;
        container.scrollTo(scrollAmount, 0);
      }
    };
    const scrollInterval = setInterval(slide, 30);
    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="logos" ref={containerRef}>
      {/* <img src={logo1} alt="Allsec" className="logo" /> */}
      <img src={logo2} alt="TeamLease" className="logo" />
      <img src={logo3} alt="HappiestMind" className="logo" />
      {/* <img src={logo4} alt="HRMantra" className="logo" /> */}
      <img src={logo5} alt="LayaTech" className="logo" />
      <img src={logo6} alt="TeamLeaseFoundation" className="logo" />
      <img src={logo7} alt="WorkHard" className="logo" />
      <img src={logo8} alt="Yethi" className="logo" />
      <img src={logo9} alt="IBN" className="logo" />
      <img src={logo10} alt="Bosch" className="logo" />
      <img src={logo11} alt="Sms" className="logo" />
    </div>
  );
};

export default LogoSliderLeftToRight;
