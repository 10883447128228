import styled from "styled-components";

const sliderdiv = styled.section`
  display: flex;
  /* max-width: 80%; */
  margin: 40 auto;
  align-items: center;
  /* background-color: #eff7ff; */
  justify-content: space-evenly;
  color: black;
  padding: 80px 7.5rem;

  @media screen and (max-width: 450px) {
    padding: 10px;
  }

  h2 {
    color: #000;
    /* font-family: Poppins; */
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
    @media screen and (max-width: 1200px) {
      font-size: 42px;
    }

    @media screen and (max-width: 450px) {
      font-size: 42px;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    /* font-family: Poppins; */
    font-size: 30px;
    font-style: normal;
    text-align: left;
    font-weight: 300;
    /* text-align: left; */
    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 420px) {
    /* font-size: 12px; */
    padding: 0;
  }

  .slick-dosts {
    margin-bottom: 15px;
  }
  .slider-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 420px) {
      flex-direction: column;
      /* margin-top: 20px; */
    }
  }

  .frame {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #b7b7b7;
    border-radius: 16px;
    display: inline-flex;
    gap: 91px;
    padding: 48px;
    position: relative;
    margin-bottom: 40px;
    border: 2px solid rgba(34, 34, 34, 0.6);
    box-shadow: rgba(34, 34, 34, 0.6) 6px 7px;

    @media screen and (max-width: 1200px) {
      width: 120%;
      gap: 10px;
      padding: 23px;
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  .frame .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
  }

  .frame .image {
    object-position: top;
    height: 207px;
    position: relative;
    width: 290px;
    object-fit: cover;
    border-radius: 10px;
  }

  .frame .mark-j-director {
    color: #222222;
    /* font-family: "Poppins-SemiBold", Helvetica; */
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  .frame .text-wrapper {
    font-weight: 600;
  }

  .frame .span {
    /* font-family: "Poppins-Regular", Helvetica; */
    font-size: 16px;
  }

  .frame .p {
    color: #222222cc;
    /* font-family: "Poppins-Regular", Helvetica; */
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 700px;
    text-align: left;
    /* width: 80%; */
    @media screen and (max-width: 1200px) {
      font-size: 16px;
      width: unset;
    }

    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
`;

export default sliderdiv;
