import styled from "styled-components";

export const BannerWrapper = styled.div`
  /* Your existing styles for the container */
  margin-top: 20px;
`;

export const NavigationWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  margin-top: 70px;
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #03baed 0%, #026a87 100%);
  padding: 10px 0;

  @media screen and (max-width: 450px) {
    /* width: 20%; */
    flex-direction: column;
    display: none;
  }
`;

export const NavigationLink = styled.button`
  display: flex;
  justify-content: space-around;
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: black;
    background-color: white;
    border-radius: 35px;
    font-size: 16px;
  }

  &:focus {
    /* outline: none; */
  }

  @media screen and (max-width: 450px) {
    /* flex-direction: column; */
    /* justify-content: space-around; */
    /* align-items: center; */
    /* color: black; */
    display: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 10px;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    &:hover {
      color: black;
      background-color: white;
      border-radius: 35px;
      font-size: 8px;
    }
  }
`;

export const IconImage = styled.img`
  height: 30px; /* Adjust the size as needed */
  width: auto;
  margin-right: 6px;
  transition: filter 0.3s ease;

  /* ${NavigationLink}:hover & {
    filter: invert(100%);
  } */
`;
