import React, { Fragment, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasModernTheme } from "common/theme/saasModern";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasModern/sassModern.style";

import HomeBannerSection from "containers/Custom/Home/HomeBannerSection";
import Navbar from "containers/SaasModern/Navbar";
import LogoSLider from "containers/HomePage/G2Badges";
import SecondSlider from "../containers/HomePage/CompanyLogoSlider";
import ScrollingVideo from "containers/Custom/Common/ScrollingVideo";
import Subbanner from "containers/AppMinimal/CallToAction";
import TwoPart from "containers/Custom/Home/TwoPart";
import ScrollingImages from "../containers/Custom/Common/ScrollingImages";
import CEOQuote from "../containers/HomePage/CEOQuote/";
import HomeFooter from "containers/AgencyModern/HomeFooter";
import { Helmet } from "react-helmet";
import Seo from "components/seo";
import Thanksgiving from "../containers/SaasModern/Thanksgiving";
import Directory from "../containers/Directory";
import G2Slider from "../containers/SaasModern/G2Badges";
import VideoSection from "../containers/SaasModern/VideoSection";
import BookDemoButton from "../containers/SaasModern/ScheduleAdemo/ButtonDemo";
import HeaderSection from "../containers/HomePage/HeaderSection";
import CustomContainer from "../containers/HomePage/Sections";
import WordMapImg from "../containers/HomePage/WorldMapG2Logo";
import Testimonial from "../containers/HomePage/TestimonialSlider";
// import Slider from "react-slick";

const HomePage = () => {
  const HomePageData = useStaticQuery(graphql`
    {
      allStrapiHomeMainBanner {
        nodes {
          titlePart1
          titlePart2
          titlePart3
          bannerDescription
        }
      }
      allStrapiHomeSubbanner {
        nodes {
          part {
            title
            description
          }
        }
      }
      allStrapiHomeCeoQuote {
        nodes {
          part {
            name
            designation
            quote
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    aspectRatio: 1
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
      allStrapiHomeScrollingImage {
        nodes {
          part {
            title
            description
            media {
              localFile {
                publicURL
              }
            }
          }
        }
      }
      allStrapiHomeScrollingVideo {
        nodes {
          part {
            title
            description
            duration
          }
        }
      }
    }
  `);

  const mainBannerData = HomePageData.allStrapiHomeMainBanner.nodes[0];
  const subbannerData = HomePageData.allStrapiHomeSubbanner.nodes[0];
  const ceoQuoteData = HomePageData.allStrapiHomeCeoQuote.nodes[0].part;
  const scrollingImagesData =
    HomePageData.allStrapiHomeScrollingImage.nodes[0].part;
  const scrollingVideoData =
    HomePageData.allStrapiHomeScrollingVideo.nodes[0].part;

  useEffect(() => {
    const ref = sessionStorage.getItem("refurl");
    console.log("", ref);
    if (document && document.referrer) {
      const url = document.referrer;
      sessionStorage.setItem("refurl", url);
    } else if (window && window.location && window.location.href && !ref) {
      const q = new URLSearchParams(window.location.search);
      const src = q.get("utm_source") ? q.get("utm_source") : "";
      sessionStorage.setItem("refurl", src);
    } else if (!ref) {
      sessionStorage.setItem("refurl", window.location.href);
    }
  }, []);

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title='Clodura - AI Powered Lead Generation Platform'
          description='Clodura is an AI-Powered Sales Prospecting Platform for Online B2B Lead Generation, Actionable Sales Intelligence, and Automated Sales Sequences.'
          robots='index, follow'
          canonical='https://www.clodura.ai/'
        />
        <Helmet>
          <script type='text/javascript'>
            {`var w = window; var p = w.location.protocol; if (p.indexOf("http") < 0) { p = "http" + ":"; } var d = document; var f = d.getElementsByTagName('script')[0], s = d.createElement('script'); s.type = 'text/javascript'; s.async = false; if (s.readyState) { s.onreadystatechange = function () { if (s.readyState == "loaded" || s.readyState == "complete") { s.onreadystatechange = null; try { loadwaprops('3ze41a8b11104434641b2af4119398d79e', '3z7095ec222050260309a35b46a5329e0e', '3za55c04fdf9cb34df731fa4b8afcfe35aca2ea2710a2e5843a0d34e51984bde7a', '3z9f5af3d4e245de73fc0ef1d57762b9be', 0.0); } catch (e) { } } }; } else { s.onload = function () { try { loadwaprops('3ze41a8b11104434641b2af4119398d79e', '3z7095ec222050260309a35b46a5329e0e', '3za55c04fdf9cb34df731fa4b8afcfe35aca2ea2710a2e5843a0d34e51984bde7a', '3z9f5af3d4e245de73fc0ef1d57762b9be', 0.0); } catch (e) { } }; }; s.src = p + '//ma.zoho.com/hub/js/WebsiteAutomation.js'; f.parentNode.insertBefore(s, f);`}
          </script>
          <script>
            {`(function(w){w.fpr=w.fpr||function(){w.fpr.q=w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window); fpr("init", {cid:"vlgrpl8w"}); fpr("click");`}
          </script>
          <script src='https://cdn.firstpromoter.com/fpr.js' async></script>
          <meta
            property='og:image'
            content='https://storage.googleapis.com/app_assets_bucket_002/website/Book-request-a-demo.png'
          />
          <meta name='yandex-verification' content='0be0132b58683231' />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <HomeBannerSection {...mainBannerData} />
          <LogoSLider />
          <SecondSlider />
          {/* <Subbanner {...subbannerData.part[0]} />
          <ScrollingVideo data={scrollingVideoData} />
          <Subbanner {...subbannerData.part[1]} />
          <TwoPart />
          <Subbanner {...subbannerData.part[2]} />
          <ScrollingImages data={scrollingImagesData} /> */}
          <CustomContainer />
          {/* <G2Slider /> */}
          <WordMapImg />
          {/* <CEOQuote {...ceoQuoteData} /> */}
          <Testimonial />

          {/* <Directory /> */}
          <HomeFooter />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default HomePage;
