import React, { useState, useEffect } from "react";
import Sticky from "react-stickynode";
import DataBase from "../ContainerSections/Database";
import SalesEngagment from "../ContainerSections/SalesEngagement";
import EmailVerificationSection from "../ContainerSections/EmailVerification";
import BuyerIntentSection from "../ContainerSections/BuyerIntent";
import DataEnrichment from "../ContainerSections/DataEnrichment";
import CRMCleanUp from "../ContainerSections/CRMCleanupAPI"; // Updated import
import {
  BannerWrapper,
  NavigationWrapper,
  NavigationLink,
} from "./container.style";

const CustomContainer = () => {
  const [activeSection, setActiveSection] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [isLastComponentReached, setIsLastComponentReached] = useState(false);
  const [isComponentsVisible, setIsComponentsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navWrapper = document.getElementById("nav-wrapper");

      if (navWrapper) {
        const navWrapperOffsetTop = navWrapper.offsetTop;

        if (scrollPosition >= navWrapperOffsetTop) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }

      // Get offset top of each section
      const databaseSection =
        document.getElementById("database-section").offsetTop;
      const salesEngagementSection = document.getElementById(
        "sales-engagement-section"
      ).offsetTop;
      const emailVerificationSection = document.getElementById(
        "email-verification-section"
      ).offsetTop;
      const buyerIntentSection = document.getElementById(
        "buyer-intent-section"
      ).offsetTop;
      const dataEnrichmentSection = document.getElementById(
        "data-enrichment-section"
      ).offsetTop;
      const crmCleanupSection = document.getElementById('crm-cleanup-section').offsetTop;


      // Determine active section based on scroll position
      if (scrollPosition < salesEngagementSection) {
        setActiveSection("database-section");
      } else if (
        scrollPosition >= salesEngagementSection &&
        scrollPosition < emailVerificationSection
      ) {
        setActiveSection("sales-engagement-section");
      } else if (
        scrollPosition >= emailVerificationSection &&
        scrollPosition < buyerIntentSection
      ) {
        setActiveSection("email-verification-section");
      } else if (
        scrollPosition >= buyerIntentSection &&
        scrollPosition < dataEnrichmentSection
      ) {
        setActiveSection("buyer-intent-section");
      } else if (
        scrollPosition >= dataEnrichmentSection &&
        scrollPosition < crmCleanupSection
      ) {
        setActiveSection("data-enrichment-section");
      } else {
        setActiveSection("crm-cleanup-section");
      }

      // Check if the user has scrolled past the last component
      const lastComponentBottom = document
        .getElementById("crm-cleanup-section")
        .getBoundingClientRect().bottom;
      if (lastComponentBottom <= window.innerHeight) {
        setIsLastComponentReached(true);
      } else {
        setIsLastComponentReached(false);
      }

      // Check if the components are in view
      const bannerWrapper = document.getElementById("banner-wrapper");
      const bannerWrapperRect = bannerWrapper.getBoundingClientRect();
      setIsComponentsVisible(
        bannerWrapperRect.top < window.innerHeight &&
          bannerWrapperRect.bottom > 0
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    setActiveSection(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <Sticky
        top={0}
        innerZ={9}
        activeClass="sticky-nav-active"
        disableClickPropagation={true}
      >
        {!isLastComponentReached && isComponentsVisible && (
          <NavigationWrapper
            id="nav-wrapper"
            className={`custom-navigation-wrapper ${isSticky ? "sticky" : ""}`}
          >
            <NavigationLink
              onClick={() => scrollToSection("database-section")}
              className={`custom-navigation-link ${
                activeSection === "database-section" ? "active" : ""
              }`}
              style={
                activeSection === "database-section"
                  ? {
                      padding: "10px 20px",
                      color: "black",
                      background: "white",
                      borderRadius: "35px",
                    }
                  : {}
              }
            >
              Database
            </NavigationLink>
            <NavigationLink
              onClick={() => scrollToSection("sales-engagement-section")}
              className={`custom-navigation-link ${
                activeSection === "sales-engagement-section" ? "active" : ""
              }`}
              style={
                activeSection === "sales-engagement-section"
                  ? {
                      padding: "10px 20px",
                      color: "black",
                      background: "white",
                      borderRadius: "35px",
                    }
                  : {}
              }
            >
              Sales Engagement
            </NavigationLink>
            <NavigationLink
              onClick={() => scrollToSection("email-verification-section")}
              className={`custom-navigation-link ${
                activeSection === "email-verification-section" ? "active" : ""
              }`}
              style={
                activeSection === "email-verification-section"
                  ? {
                      padding: "10px 20px",
                      color: "black",
                      background: "white",
                      borderRadius: "35px",
                    }
                  : {}
              }
            >
              Email Verification
            </NavigationLink>
            <NavigationLink
              onClick={() => scrollToSection("buyer-intent-section")}
              className={`custom-navigation-link ${
                activeSection === "buyer-intent-section" ? "active" : ""
              }`}
              style={
                activeSection === "buyer-intent-section"
                  ? {
                      padding: "10px 20px",
                      color: "black",
                      background: "white",
                      borderRadius: "35px",
                    }
                  : {}
              }
            >
              Buyer Intent
            </NavigationLink>
            <NavigationLink
              onClick={() => scrollToSection("data-enrichment-section")}
              className={`custom-navigation-link ${
                activeSection === "data-enrichment-section" ? "active" : ""
              }`}
              style={
                activeSection === "data-enrichment-section"
                  ? {
                      padding: "10px 20px",
                      color: "black",
                      background: "white",
                      borderRadius: "35px",
                    }
                  : {}
              }
            >
              Data Enrichment
            </NavigationLink>
            <NavigationLink
              onClick={() => scrollToSection("crm-cleanup-section")}
              className={`custom-navigation-link ${
                activeSection === "crm-cleanup-section" ? "active" : ""
              }`}
              style={
                activeSection === "crm-cleanup-section"
                  ? {
                      padding: "10px 20px",
                      color: "black",
                      background: "white",
                      borderRadius: "35px",
                    }
                  : {}
              }
            >
              CRM Cleanup
            </NavigationLink>
          </NavigationWrapper>
        )}
      </Sticky>
      <BannerWrapper id="banner-wrapper" className="custom-banner-wrapper">
        <div id="database-section">
          <DataBase />
        </div>
        <div id="sales-engagement-section">
          <SalesEngagment />
        </div>
        <div id="email-verification-section">
          <EmailVerificationSection />
        </div>
        <div id="buyer-intent-section">
          <BuyerIntentSection />
        </div>
        <div id="data-enrichment-section">
          <DataEnrichment />
        </div>
        <div id="crm-cleanup-section">
          <CRMCleanUp />
        </div>
      </BannerWrapper>
    </div>
  );
};

export default CustomContainer;
