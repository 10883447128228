import React from "react";
import RightArrowIcon from "../../../../common/assets/image/saasModern/HomePageImg/ArrowRight.svg";
import { Link } from "gatsby";
import CRMCleanUpMain from "../../../../common/assets/image/saasModern/HomePageImg/CRM Cleanup API Main.png";
import {
  BannerWrapper,
  ContainerWrapper,
  SubContainerWrapper,
  VerticalLine,
} from "./crmCleanupApi.style.js";

const CrmCleanUpContainer = () => {
  return (
    <BannerWrapper>
      <h2>CRM Cleanup API</h2>
      <p>
        Our CRM Cleanup API empowers you to proactively manage and <br />
        improve the quality of data within your existing CRM system. 
      </p>
      <ContainerWrapper>
        <div className='Img-Container'>
          <img className='img' src={CRMCleanUpMain} />
        </div>
        <main>
          <div className='box'>
            {/* <div className="img-container">
              <img src={DataEnrichmentCSV} />
            </div> */}
            <div className='heading-text'>Identify Duplicates</div>
            <div className='paragraph-text'>
              Efficiently locate and merge duplicate records, eliminating
              redundancy and improving data accuracy.
            </div>
          </div>
          <VerticalLine />
          <div className='box'>
            {/* <div className="img-container">
              <img src={DataEnrichmentCSV} />
            </div> */}
            <div className='heading-text'>Revamp Old Contacts</div>
            <div className='paragraph-text'>
              Breathe new life into outdated contact information by updating job
              positions, email addresses, company affiliations, and more. 
            </div>
          </div>
          <VerticalLine />
          <div className='box'>
            {/* <div className="img-container">
              <img src={WebsiteEnrichment} />
            </div> */}
            <div className='heading-text'>Validate & Enrich Data</div>
            <div className='paragraph-text'>
              Leverage social profiles as unique identifiers to match current
              information and enrich your data with valuable insights. 
            </div>
          </div>
        </main>
      </ContainerWrapper>
      <Link href='/crm-cleanup-apis/'>
        <button className='btn'>
          Learn More
          <img src={RightArrowIcon} />
        </button>
      </Link>

      {/* <div className='btn'>Learn More</div> */}
    </BannerWrapper>
  );
};

export default CrmCleanUpContainer;
