import React from 'react';
import Arrow from '../../../common/assets/image/saasModern/HomePageImg/ArrowUp.svg';
import ArrowBlack from '../../../common/assets/image/saasModern/HomePageImg/ArrowBlack.svg';
import { Parallax } from 'react-parallax';
import { BannerWrapper } from './header.style';

const BannerSection = () => {
  return (
    <BannerWrapper id="banner_section">
      <h1 className="headings-container">
        One Leadgen Platform
        <br />
        To Replace Them All
      </h1>

      <h3 className="secondheading-container">
        Database <span className="pipe-spn">+</span> Sales Engagement{' '}
        <span className="pipe-spn">+</span> Email Verification{' '}
        <span className="pipe-spn">+</span> Buyer Intent{' '}
        <span className="pipe-spn">+</span> Data Enrichment
      </h3>
      {/* <div className="btn-container">
        <button className="btnForSignUp" style={{ '--delay': 1 }}>
          Free Sign Up
          <img src={Arrow} alt="arrow" />
        </button>
        <button className="btnForDemoBook" style={{ '--delay': 2 }}>
          Book A Demo
          <img src={ArrowBlack} alt="arrow" />
        </button>
      </div>
      <Parallax style={{ background: 'blue' }} className="test" /> */}
    </BannerWrapper>
  );
};

export default BannerSection;
