import styled from "styled-components";

export const BannerWrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 80px 0 0 0;
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #000;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    @media screen and (max-width: 1200px) {
      font-size: 42px;
    }

    @media screen and (max-width: 450px) {
      font-size: 42px;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
`;

export const ContainerWrapper = styled.div`
  .frame {
    align-items: flex-start;
    background-color: #eff6ff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 1320px;
    @media screen and (max-width: 1200px) {
      /* width: 80%;
      align-items: center; */
    }
  }

  .frame .div {
    align-items: flex-start;
    background-color: #4099ff17;
    border-radius: 12px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 1272px;
  }

  .frame .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }
  }

  .frame .div-52 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      height: 1200px;
    }
    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      height: 1200px;
    }
  }

  .frame .div-3 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 11px;
    height: 542px; /* Default height */
    overflow: hidden;
    padding: 24px;
    position: relative;

    @media screen and (max-width: 1200px) {
      width: 50%;
      height: auto; /* Adjust height for smaller screens */
    }

    @media screen and (max-width: 450px) {
      width: 30%;
      height: auto; /* Adjust height for smaller screens */
    }
  }

  .frame .div-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
  }

  .frame .text-wrapper {
    color: #1b1b1b;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26.1px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      font-size: 28px;
    }
  }

  .left-margin-text {
    @media screen and (max-width: 1600px) {
      text-align: center;
      margin-left: 450px;
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
    @media screen and (max-width: 450px) {
      margin-left: 0;
    }
  }
  .frame .p {
    align-self: stretch;
    /* color: #00000080; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20.9px;
    position: relative;
    text-align: left;
    top: 300px;
  }

  .frame .div-5 {
    align-items: flex-start;
    align-self: stretch;
    border-color: #ebebeb;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    padding: 10px 0px 0px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  .frame .div-6 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #ededed;
    border-radius: 18px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    overflow: hidden;
    padding: 10px 16px;
    position: relative;

    @media screen and (max-width: 1200px) {
      /* width: 60%; */
      position: relative;
      /* font-size: 12px; */
      width: 45%;
    }

    @media screen and (max-width: 450px) {
      /* width: 60%; */
      position: relative;
      /* font-size: 12px; */
      width: 100%;
    }
  }

  .frame .div-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }

  .frame .div-8 {
    background: linear-gradient(
      180deg,
      rgb(3, 186, 237) 1.13%,
      rgb(1.71, 105.95, 135) 100%
    );
    border-radius: 24.5px;
    height: 49px;
    position: relative;
    width: 49px;
  }

  .frame .img {
    height: 29px;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 29px;
  }

  .frame .div-9 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

  .frame .text-wrapper-2 {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 31.3px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
    @media screen and (max-width: 450px) {
      font-size: 12px;
    }
  }

  .frame .text-wrapper-3 {
    color: #000000;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 26.1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;

    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      font-size: 10px;
    }
  }

  .frame .group {
    height: 287px;
    left: 23px;
    position: absolute;
    top: 170px;
    width: 542px;
    @media screen and (max-width: 1200px) {
      /* width: 60%; */
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      height: 270px;
    }
    @media screen and (max-width: 450px) {
      /* width: 60%; */
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      height: 230px;
    }
  }

  .frame .div-10 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 542px;
    padding: 24px;
    position: relative;
    width: 600px;

    @media screen and (max-width: 450px) {
      width: 30%;
    }
  }

  .frame .div-11 {
    align-items: flex-start;
    align-self: stretch;
    border-color: #ebebeb;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    gap: 24px;
    height: 456px;
    margin-bottom: -52px;
    padding: 10px 0px 0px;
    position: relative;
    width: 100%;
  }

  .frame .full-width {
    height: 287px;
    left: 17px;
    position: absolute;
    top: 110px;
    width: 509px;
    @media screen and (max-width: 450px) {
      width: 100%;
      top: 100px;
      left: 0;
    }
  }

  .frame .layer {
    height: 35px;
    left: 15px;
    position: absolute;
    top: 7px;
    width: 18px;
  }

  .frame .div-12 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    position: relative;
    width: 1224px;
    @media screen and (max-width: 1200px) {
      align-items: center;
    }
    @media screen and (max-width: 450px) {
      align-items: center;
    }
  }

  .frame .div-13 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 2px 1px #0000000d;
    display: flex;
    gap: 24px;
    height: 424px;
    margin-right: -4px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      /* width: 30%; */
      overflow: visible;
      height: 950px;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      /* width: 30%; */
      overflow: visible;
      height: 950px;
    }
  }

  .frame .div-14 {
    align-items: flex-start;
    background-color: #ffffff33;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 480px;
    margin-bottom: -56px;
    padding: 25px 24px;
    position: relative;
    width: 574px;

    @media screen and (max-width: 450px) {
      /* width: 60%; */
      position: relative;
      /* font-size: 12px; */
      width: 100%;
      margin-bottom: -20px;

      /* height: 2000px; */
    }
  }

  .frame .text-wrapper-4 {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20.9px;
    margin-top: -1px;
    position: relative;
    width: 415px;
  }

  .frame .text-wrapper-5 {
    /* color: #00000080; */
    font-size: 16px;
    font-weight: 400;
    left: 28px;
    letter-spacing: 0;
    line-height: 20.9px;
    position: absolute;
    top: 395px;
    width: 415px;
  }

  .frame .full-width-2 {
    height: 300px;
    left: 24px;
    position: absolute;
    top: 72px;
    width: 528px;
    @media screen and (max-width: 450px) {
      width: 88%;
    }
  }
  .frame .full-width-3 {
    height: 312px;
    left: 24px;
    position: absolute;
    top: 62px;
    width: 528px;
    @media screen and (max-width: 450px) {
      width: 88%;
    }
  }

  .frame .div-15 {
    border-color: #ebebeb;
    border-top-style: solid;
    border-top-width: 1px;
    height: 91px;
    left: 26px;
    position: absolute;
    top: 56px;
    width: 526px;
  }

  .frame .img-2 {
    height: 424px;
    position: relative;
    width: 582px;
  }

  .frame .group-2 {
    height: 305px;
    left: 646px;
    position: absolute;
    top: 138px;
    width: 542px;
  }

  .frame .images-final {
    height: 329px;
    left: 797px;
    object-fit: cover;
    position: absolute;
    top: 114px;
    width: 280px;
    @media screen and (max-width: 450px) {
      object-fit: contain;
    }
  }

  .frame .div-16 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
    height: 587px;
    padding: 24px;
    position: relative;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      width: 50%;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      width: 30%;
    }
  }

  .frame .div-17 {
    align-items: flex-start;
    align-self: stretch;
    border-color: #ebebeb;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    padding: 10px 0px 0px;
    position: relative;
    width: 100%;
  }

  .frame .overlap-group-wrapper {
    height: 327px;
    left: 0;
    position: absolute;
    top: 106px;
    width: 552px;
  }

  .frame .overlap-group {
    height: 320px;
    position: relative;
    top: 7px;
  }

  .frame .group-3 {
    height: 311px;
    left: 0;
    position: absolute;
    top: 0;
    width: 552px;
  }

  .frame .images {
    height: 307px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: -10px;
    width: 552px;
    @media screen and (max-width: 450px) {
      width: 58%;
      /* height: 0; */
      object-fit: contain;
    }
  }

  .frame .layer-2 {
    height: 28px;
    left: 6px;
    position: absolute;
    top: 7px;
    width: 36px;
  }

  .frame .div-18 {
    border-color: #ebebeb;
    border-top-style: solid;
    border-top-width: 1px;
    height: 91px;
    left: 26px;
    position: absolute;
    top: 63px;
    width: 541px;
  }

  .frame .div-wrapper {
    height: 402px;
    left: 24px;
    position: absolute;
    top: 157px;
    width: 539px;
  }

  .frame .overlap-group-2 {
    height: 320px;
    position: relative;
    top: 11px;
  }

  .frame .group-4 {
    height: 303px;
    left: 0;
    position: absolute;
    top: 0;
    width: 539px;
  }

  .frame .images-2 {
    height: 300px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 4px;
    width: 552px;
    @media screen and (max-width: 450px) {
      width: 60%;
      object-fit: contain;
    }
  }
`;

export const SubContainerWrapper = styled.div`
  background-color: #f6f6f6;
`;

export default BannerWrapper;
