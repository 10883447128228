import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const BannerWrapper = styled.section`
  padding: 125px 0 0 0;
  max-width: 70%;
  margin: 0 auto;
  background-size: cover;
  background-position: top center;

  @media (max-width: 575px) {
    padding: 120px 0 0 0;
  }

  .headings-container,
  .secondheading-container,
  .third-container,
  .fouth-container,
  .btn-container {
    animation: ${fadeIn} 1s ease forwards;
    opacity: 0; /* Start with opacity 0 */
  }

  .headings-container {
    font-size: 40px;
    letter-spacing: -0.025em;
    margin: 0;
    margin-bottom: 25px;
    line-height: 1.2;
    text-align: center;
  }

  .third-container {
    animation-delay: 1.5s;
  }

  .fouth-container {
    animation-delay: 1.8s;
  }
  .book-a-demo {
    background-color: #f58634;
  }

  /* .book-a-demo:hover {
    background-color: #86bc4c;
  } */
  .watch-demo-btn {
    background-color: white;
    margin-left: 10px;
    cursor: pointer;
    color: #03baed;
    font-size: 14px;
    border: 2px solid rgb(3, 186, 237);

    @media only screen and (max-width: 400px) {
      margin-left: 0;
      margin-top: 4px;
      font-size: 13px;
    }
  }

  /* .watch-demo-btn:hover {
    color: orange;
  } */

  /* CSS for the close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #333; /* Change the color to your desired color */
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s, color 0.3s;
  }

  /* Hover effect for the close button */
  .close-button:hover {
    background-color: #333; /* Change the background color on hover */
    color: white; /* Change the text color on hover */
  }

  .green {
    color: #86bc4c;
    background-color: white;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .fill-input {
    font-size: 14px;
    border-radius: 4px;
    border: 2px solid rgb(3, 186, 237);
    padding: 8px 22px;
    min-width: 350px;
    min-height: auto;
    margin-right: 20px;

    @media only screen and (max-width: 400px) {
      margin-right: 0;
      margin-bottom: 4px;
    }
  }

  .anim_container > svg {
    box-shadow: 0 0 10px 0 lightgray;
  }

  @media only screen and (max-width: 576px) {
    .fill-input {
      min-width: 250px;
    }

    .email-signup {
      flex-direction: column;
    }
    .email-signup > a {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 400px) {
    .fill-input {
      min-width: 150px;
    }
  }
`;

export const DiscountWrapper = styled.div`
  text-align: center;
`;

export const Headings = styled.div`
  font-family: 'Poppins', sans-serif;
`;

export const DiscountLabel = styled.div`
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  border-radius: 4em;
  padding: 9px 22px;
  margin-bottom: 22px;
  background-color: rgba(255, 255, 255, 0.15);
  @media (max-width: 575px) {
    padding: 7px 10px;
  }
`;
