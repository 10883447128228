import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import { BannerWrapper } from './banner.style';
import media from 'common/assets/videos/home/HomeAnim.json';

const HomeBannerSection = ({ imageWrapper, imageArea }) => {
  const [isNormalShape, setIsNormalShape] = useState(false);
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const animationContainerRef = useRef();
  const animationInstanceRef = useRef(null);

  useEffect(() => {
    if (!isInitialized) {
      animationInstanceRef.current = lottie.loadAnimation({
        container: animationContainerRef.current,
        renderer: '',
        loop: true,
        autoplay: false,
        animationData: media,
        width: '80%',
        rendererSettings: {
          viewBoxSize: '150 150 1250 700',
        },
      });

      setIsInitialized(true);
    }

    const halfViewportHeight = window.innerHeight / 2;

    const handleScroll = () => {
      const boundingRect =
        animationContainerRef.current.getBoundingClientRect();
      if (
        !isNormalShape &&
        boundingRect.top < halfViewportHeight &&
        boundingRect.bottom >= 0
      ) {
        setIsNormalShape(true);
        animationInstanceRef.current.play();
      }
    };

    const handleTransitionEnd = () => {
      setIsAnimationCompleted(true);
    };

    window.addEventListener('scroll', handleScroll);
    animationContainerRef.current.addEventListener(
      'transitionend',
      handleTransitionEnd
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
      animationContainerRef.current.removeEventListener(
        'transitionend',
        handleTransitionEnd
      );
    };
  }, [isNormalShape, isInitialized]);

  return (
    <BannerWrapper
      id="banner_section"
      isNormalShape={isNormalShape}
      isAnimationCompleted={isAnimationCompleted}
    >
      <div className="svg-wrapper">
        <div ref={animationContainerRef} className="anim_container"></div>
      </div>
    </BannerWrapper>
  );
};

export default HomeBannerSection;
