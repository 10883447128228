import React from "react";

import SalesTriggerIcon from "../../../../common/assets/image/saasModern/HomePageImg/SalesTriggerIcon.svg";
import IntentFilterIcon from "../../../../common/assets/image/saasModern/HomePageImg/IntentFilter.svg";
import JobOpeningIcon from "../../../../common/assets/image/saasModern/HomePageImg/JobIcon.svg";
import RightArrowIcon from "../../../../common/assets/image/saasModern/HomePageImg/ArrowRight.svg";
import { Link } from "gatsby";
import {
  BannerWrapper,
  ContainerWrapper,
  SubContainerWrapper,
} from "./buyerIntent.style";

const BuyerIntentContainer = () => {
  return (
    <BannerWrapper>
      <h2>Buyer Intent</h2>
      <p>
        Identify & Engage Ready-to-Buy Prospects. Uncover Buying Signals and
        Prioritize Leads for Maximum Impact
      </p>
      <ContainerWrapper>
        <div className='frame'>
          <div className='overlap'>
            <main>
              <div className='box'>
                <div className='heading-section'>
                  <div>
                    <img className='heading-icons' src={SalesTriggerIcon} />
                  </div>
                  <div className='heading-text'>
                    <div className='heading-h'>Sales Triggers</div>
                    <text>(25+ Customizable Triggers)</text>
                  </div>
                </div>
                <div className='p-text'>
                  Set up automated alerts based on specific actions taken by
                  potential customers on your website or social media,
                  indicating their <b>active interest</b>.
                </div>
              </div>
              <div className='box'>
                <div className='heading-section'>
                  <div>
                    <img className='heading-icons' src={IntentFilterIcon} />
                  </div>
                  <div className='heading-text'>
                    <div className='heading-h'>Intent Filters</div>
                    {/* <text>(25+ Customizable Triggers)</text> */}
                  </div>
                </div>
                <div className='p-text'>
                  Apply specific filters based on buyer intent signals to{" "}
                  <b>refine your search </b>and{" "}
                  <b>target only the most promising prospects</b>.
                </div>
              </div>
              <div className='box'>
                <div className='heading-section'>
                  <div>
                    <img className='heading-icons' src={JobOpeningIcon} />
                  </div>
                  <div className='heading-text'>
                    <div className='heading-h'>Job Openings</div>
                    {/* <text>(25+ Customizable Triggers)</text> */}
                  </div>
                </div>
                <div className='p-text'>
                  Leverage real-time job opening data to{" "}
                  <b>identify companies actively seeking solutions</b> related
                  to your offerings.
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </main>

            <div className='go-beyond-wrapper'>
              <p className='go-beyond'>
                Go beyond traditional prospecting methods and leverage the power
                of buyer intent data to identify prospects actively searching
                for solutions like yours.
              </p>
            </div>
          </div>
        </div>
      </ContainerWrapper>
      <Link href='/sales-intelligence/'>
        <button className='btn'>
          Learn More
          <img src={RightArrowIcon} />
        </button>
      </Link>
    </BannerWrapper>
  );
};

export default BuyerIntentContainer;
