import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderDiv from "./sliderdiv.style";
import MarkJ from "../../../common/assets/image/saasModern/HomePageImg/MarkJ.png";
import StuartK from "../../../common/assets/image/saasModern/HomePageImg/StuartK.png";
import Rebecca from "../../../common/assets/image/saasModern/HomePageImg/Chief Revenue Officer - Leading IT company.jpg";
import "./style.css";

const SliderSection = () => {
  const [dotsMarginTop, setDotsMarginTop] = useState("-60px");

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.innerWidth <= 420;
      setDotsMarginTop(isMobileScreen ? "100px" : "-80px");
    };

    handleWindowResize(); // Set initial value
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    pauseOnHover: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    appendDots: (dots) => (
      <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
        <ul
          style={{ margin: "0", textAlign: "center", marginTop: dotsMarginTop }}
        >
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className='heading-2'>Testimonials</div>
      <div className='paragraph-p'>What people say about us</div>
      <Slider {...settings}>
        <SliderDiv>
          <div className='slider-wrapper'>
            <div className='frame'>
              <div className='div'>
                <img className='image' alt='Image' src={MarkJ} />
                <p className='mark-j-director'>
                  <span className='text-wrapper'>
                    Mark J.
                    <br />
                  </span>
                  <span className='span'>
                    Director Sales - Leading IT company
                  </span>
                </p>
              </div>
              <p className='p'>
                Having previously utilized ZoomInfo and Slintel for our B2B
                contact database needs, we often found ourselves dissatisfied
                with their quality and coverage. However, since incorporating
                Clodura.AI into our workflow, we've experienced a remarkable
                difference. Not only does Clodura.AI offer impressive quality
                and coverage, extending beyond the borders of the USA, but its
                intent feature has proven to be a game-changer for us. By
                pinpointing low-hanging sales opportunities, it has
                significantly reduced our customer acquisition costs (CAC).
                {/* allowing us to optimize our resources and maximize our ROI. */}
              </p>
            </div>
          </div>
        </SliderDiv>
        <SliderDiv>
          <div className='slider-wrapper'>
            <div className='frame'>
              <div className='div'>
                <img className='image' alt='Image' src={Rebecca} />
                <p className='mark-j-director'>
                  <span className='text-wrapper'>
                    Rebecca M.
                    <br />
                  </span>
                  <span className='span'>
                    Chief Revenue Officer - Leading IT company
                  </span>
                </p>
              </div>
              <p className='p'>
                Clodura.AI has been a game-changer for our lead generation
                efforts. By automating the process, it has not only saved us
                significant time and effort but has also streamlined our
                workflow. With its ability to swiftly find contacts, emails, and
                phone numbers, our outreach has become more targeted and
                efficient. Moreover, the capability to automate our cold email
                campaigns has further enhanced our outreach strategy, allowing
                us to reach more prospects effectively. We are incredibly
                grateful for the transformative impact Clodura.AI has had on our
                business. Thank you, Clodura.AI, for empowering us with such a
                powerful tool.
              </p>
            </div>
          </div>
        </SliderDiv>
        <SliderDiv>
          <div className='slider-wrapper'>
            <div className='frame'>
              <div className='div'>
                <img className='image' alt='Image' src={StuartK} />
                <p className='mark-j-director'>
                  <span className='text-wrapper'>
                    Stuart K.
                    <br />
                  </span>
                  <span className='span'>Senior Vice President</span>
                </p>
              </div>
              <p className='p'>
                The search functionality and contacts delivered strong results
                for prospecting. We were looking for software companies, not end
                users, for many of our verticals, and Clodura was the only
                company we found to be able to deliver the information. In
                addition, there is additional functionality for marketing &
                email campaigns that don't exist with other tools like this. The
                team was incredibly responsive with any customer support or
                client success functions, as well as software issues, and would
                stay up pretty late to help resolve or fix the problem.
              </p>
            </div>
          </div>
        </SliderDiv>
      </Slider>
    </div>
  );
};

export default SliderSection;
