import styled from 'styled-components';

export const BannerWrapper = styled.section`
  padding: 10px 0 10px 0;
  max-width: 100%;
  margin: 0 auto;
  background-size: cover;
  background-position: top center;
  background: ${({ isNormalShape }) =>
    isNormalShape
      ? 'radial-gradient(50% 50% at 50% 50%, #60dcff 0%, #fff 100%)'
      : 'none'};

  .anim_container {
    transform: perspective(500px)
      rotateX(${(props) => (props.isNormalShape ? '0deg' : '15deg')})
      scaleY(${(props) => (props.isNormalShape ? '1' : '0.7')});
    transform-origin: center;
    transition: transform 1.5s ease;
  }

  .anim_container > svg {
    border-radius: 20px;
    box-shadow: 0 0 10px 0 lightgray;
    background-color: white;
  }

  .svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 65%;
  }
`;
