import React, { useEffect, useRef } from 'react';
import './style.css'; // Import CSS file for styles
import logo1 from '../../../common/assets/image/saasModern/HomePageImg/Allsec.png';
import logo2 from '../../../common/assets/image/saasModern/HomePageImg/TeamLease.png';
import logo3 from '../../../common/assets/image/saasModern/HomePageImg/HappiestMind.png';
import logo4 from '../../../common/assets/image/saasModern/HomePageImg/HRMantra.png';
import logo5 from '../../../common/assets/image/saasModern/HomePageImg/LayaTech.png';
import logo6 from '../../../common/assets/image/saasModern/HomePageImg/TeamLeaseFoundation.png';
import logo7 from '../../../common/assets/image/saasModern/HomePageImg/WorkHard.png';
import logo8 from '../../../common/assets/image/saasModern/HomePageImg/Yethi.png';
import logo9 from '../../../common/assets/image/saasModern/HomePageImg/IBN.png';
// import logo10 from '../../../common/assets/image/saasModern/HomePageImg/Bosch.png';
// import logo11 from '../../../common/assets/image/saasModern/HomePageImg/Sms.png';

const LogoSliderRightToLeft = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let scrollAmount = 0;
    const slide = () => {
      scrollAmount += 2;
      container.scrollTo(scrollAmount, 0);
      if (scrollAmount >= container.scrollWidth - container.clientWidth) {
        // Reset scroll position to start when it reaches the end
        scrollAmount = 0;
        container.scrollTo(scrollAmount, 0);
      }
    };
    const scrollInterval = setInterval(slide, 30);
    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="logos" ref={containerRef}>
      <img src={logo1} alt="Allsec" className="logo" />
      <img src={logo2} alt="TeamLease" className="logo" />
      <img src={logo3} alt="HappiestMind" className="logo" />
      <img src={logo4} alt="HRMantra" className="logo" />
      <img src={logo5} alt="LayaTech" className="logo" />
      <img src={logo6} alt="TeamLeaseFoundation" className="logo" />
      <img src={logo7} alt="WorkHard" className="logo" />
      <img src={logo8} alt="Yethi" className="logo" />
      <img src={logo9} alt="IBN" className="logo" />
      {/* <img src={logo10} alt="Bosch" className="logo" />
      <img src={logo11} alt="Sms" className="logo" /> */}
    </div>
  );
};

export default LogoSliderRightToLeft;
