import styled from "styled-components";

export const BannerWrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 130px 0 0 0;
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #000;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    @media screen and (max-width: 1200px) {
      font-size: 42px;
    }

    @media screen and (max-width: 450px) {
      font-size: 42px;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }

  .btn {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #03baed;
    width: 220px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: white;
    gap: 8px;
    border-radius: 10px;
    border: none;
  }
`;

export const ContainerWrapper = styled.div`
  .frame {
    height: 480px;
    position: relative;
    width: 1442px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      height: 1200px;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }
  }

  main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    padding: 40px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }
  }

  .box {
    border-radius: 13px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(162, 162, 162, 0.25);
    padding: 40px;
    width: 30%;
  }

  .heading-section {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .heading-text {
    margin-left: 10px;
  }

  .heading-icons {
    height: 80px;
    width: 80px;
  }

  .heading-h {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }
  b {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  text {
    margin-top: -5px;
  }
  .p-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
  }

  .frame .overlap {
    background-color: #e0efff;
    height: 464px;
    left: 0;
    position: absolute;
    /* top: 201px; */
    width: 1442px;
  }

  .frame .overlap-group {
    background-color: #ffffff;
    border-radius: 13px;
    box-shadow: 0px 0px 12px #a2a2a240;
    height: 249px;
    left: 75px;
    position: absolute;
    top: 52px;
    width: 420px;
  }

  .frame .sales-triggers {
    color: #000000;

    font-size: 24px;
    font-weight: 400;
    left: 132px;
    letter-spacing: 0;
    line-height: 24px;
    position: absolute;
    top: 44px;
  }

  .frame .text-wrapper {
    font-weight: 500;
    line-height: 31.3px;
  }

  .frame .span {
    font-size: 16px;
    line-height: 28.8px;
  }

  .frame .set-up-automated {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    left: 35px;
    letter-spacing: 0;
    line-height: 20.9px;
    position: absolute;
    top: 136px;
    width: 363px;
  }

  .frame .text-wrapper-2 {
    font-weight: 300;
  }

  .frame .text-wrapper-3 {
    font-weight: 500;
  }

  .frame .frame-wrapper {
    background-color: #03baed;
    border-radius: 41.5px;
    height: 83px;
    left: 35px;
    position: absolute;
    top: 35px;
    width: 83px;
  }

  .frame .div {
    background-image: url(./image-1903.svg);
    background-position: 50% 50%;
    background-size: cover;
    height: 50px;
    left: 17px;
    position: relative;
    top: 16px;
    width: 49px;
  }

  .frame .overlap-2 {
    background-color: #ffffff;
    background-size: 100% 100%;
    height: 273px;
    left: 527px;
    position: absolute;
    top: 40px;
    width: 409px;
  }

  .frame .text-wrapper-4 {
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    left: 139px;
    letter-spacing: 0;
    line-height: 31.3px;
    position: absolute;
    top: 71px;
    white-space: nowrap;
  }

  .frame .apply-specific {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    left: 39px;
    letter-spacing: 0;
    line-height: 20.9px;
    position: absolute;
    top: 158px;
    width: 347px;
  }

  .frame .div-wrapper {
    background-color: #03baed;
    border-radius: 41.5px;
    height: 83px;
    left: 39px;
    position: absolute;
    top: 46px;
    width: 83px;
  }

  .frame .overlap-group-wrapper {
    background-image: url(./p60a-cb.svg);
    background-size: 100% 100%;
    height: 67px;
    left: 14px;
    position: relative;
    top: 9px;
    width: 56px;
  }

  .frame .uaixqm-tif-wrapper {
    background-image: url(./vector.svg);
    background-size: 100% 100%;
    height: 14px;
    left: 27px;
    position: relative;
    top: 18px;
    width: 14px;
  }

  .frame .uaixqm-tif {
    height: 10px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 8px;
  }

  .frame .overlap-3 {
    background-color: #ffffff;
    border-radius: 13px;
    box-shadow: 0px 0px 12px #a2a2a240;
    height: 249px;
    left: 969px;
    position: absolute;
    top: 52px;
    width: 379px;
  }

  .frame .text-wrapper-5 {
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    left: 124px;
    letter-spacing: 0;
    line-height: 31.3px;
    position: absolute;
    top: 59px;
    white-space: nowrap;
  }

  .frame .leverage-real-time {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    left: 27px;
    letter-spacing: 0;
    line-height: 20.9px;
    position: absolute;
    top: 146px;
    width: 352px;
  }

  .frame .img-wrapper {
    background-color: #03baed;
    border-radius: 41.5px;
    height: 83px;
    left: 27px;
    position: absolute;
    top: 34px;
    width: 83px;
  }

  .frame .img {
    height: 55px;
    left: 16px;
    position: absolute;
    top: 14px;
    width: 51px;
  }

  .frame .go-beyond-wrapper {
    background-color: #ffffff;
    border-radius: 13px;
    height: 93px;
    left: 75px;
    position: absolute;
    top: 339px;
    width: 1273px;
    @media screen and (max-width: 1200px) {
      top: 923px;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }
  }

  .frame .go-beyond {
    color: #000000cc;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 25px;
    font-weight: 400;
    left: 76px;
    letter-spacing: 0;
    line-height: 32.6px;
    position: absolute;
    text-align: center;
    top: 13px;
    width: 1120px;
  }

  .frame .text-wrapper-6 {
    color: #000000cc;
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 32.6px;
  }

  .frame .text-wrapper-7 {
    font-weight: 600;
  }

  .frame .text-wrapper-8 {
    color: #000000;
    font-size: 60px;
    font-weight: 400;
    left: 533px;
    letter-spacing: 0;
    line-height: 78.3px;
    position: absolute;
    top: -1px;
    white-space: nowrap;
  }

  .frame .identify-engage {
    color: #000000cc;
    font-size: 25px;
    font-weight: 300;
    left: 321px;
    letter-spacing: 0;
    line-height: 32.6px;
    position: absolute;
    text-align: center;
    top: 96px;
    width: 781px;
    @media screen and (max-width: 1200px) {
      /* top:0; */
    }

    @media screen and (max-width: 450px) {
      font-size: 16px;
    }
  }
`;

export const SubContainerWrapper = styled.div`
  background-color: #f6f6f6;
`;

export default BannerWrapper;
