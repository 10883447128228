import styled from "styled-components";

export const BannerWrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 80px 0 0 0;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  h2 {
    color: #000;
    font-family: Poppins;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
    @media screen and (max-width: 1200px) {
      font-size: 42px;
    }

    @media screen and (max-width: 450px) {
      font-size: 42px;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    /* text-align: left; */
    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #03baed;
    width: 220px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: white;
    gap: 8px;
    border-radius: 10px;
    border: none;
  }
`;

export const ContainerWrapper = styled.div`
  .Img-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
  }
  main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    /* padding: 40px; */
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }
  }
  .box {
    border-radius: 13px;
    background: #fff;
    /* box-shadow: 0px 0px 12px 0px rgba(162, 162, 162, 0.25); */
    padding: 5px;
    width: 40%;
    /* position: relative; */
    @media screen and (max-width: 1200px) {
      width: 100%;
      gap: 0;
    }

    @media screen and (max-width: 450px) {
      gap: 0;
      width: 100%;
    }
  }

  .img {
    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  .heading-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .paragraph-text {
    min-height: 160px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    padding-right: 10px;
  }
  b {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #03baed;
    /* width: 80px; */
  }
`;

export const VerticalLine = styled.div`
  height: 110px;
  width: 4px;
  background-color: #03baed;
  margin-top: -80px;
  /* position: absolute; */
  @media screen and (max-width: 1200px) {
    display: none;
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const SubContainerWrapper = styled.div`
  background-color: #f6f6f6;
`;

export default BannerWrapper;
