import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import media from 'common/assets/videos/home/HomeAnim.json';
import HeaderSection from '../../../../containers/HomePage/HeaderSection';
import SubSection from '../../../../containers/HomePage/SubSection';

import Modal from 'react-modal'; // Import the modal library

import { BannerWrapper } from './banner.style';
import HomeVideoBanner from '../../../Agency/HomeVideoBanner';

const HomeBannerSection = ({
  row,
  contentWrapper,
  description,
  imageWrapper,
  imageArea,
  buttonWrapper,
  fillButton,
  titlePart1,
  titlePart2,
  titlePart3,
  bannerDescription,
}) => {
  const bannedEmailDomains = [
    'gmail.com',
    // ... add other banned domains here
  ];

  const [email, setEmail] = useState('');
  const [buttonActive, setButtonActive] = useState(false);
  const [showEmailWarning, setShowEmailWarning] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false); // State to track modal open/close
  const [link, setLink] = useState(''); // State to store YouTube video link

  // Function to open the modal and set the YouTube video link
  const openVideoModal = (videoLink) => {
    setLink(videoLink);
    setVideoOpen(true);
  };

  // Function to close the modal
  const closeVideoModal = () => {
    setVideoOpen(false);
    setLink('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailDomain = email.split('@')[1].toLowerCase();
    if (bannedEmailDomains.includes(emailDomain)) {
      setShowEmailWarning(true); // Display the warning message
      return;
    }

    try {
      const response = await fetch(
        `https://m.api.azr.clodura.com/freemailpros?msp_eq=${emailDomain}`,
        {
          method: 'GET',
          mode: 'cors',
          credentials: 'same-origin',
        }
      );
      if (response.ok) {
        const res = await response.json();
        if (res.length === 0) {
          // The email domain is not associated with a free email service provider
          // Proceed with your logic for sending data to the API or other actions
          const body = { data: { email } };
          const response = await fetch(
            'https://m.api.corp.azr.clodura.ai/api/clodura-signups/',
            {
              method: 'POST',
              mode: 'cors',
              credentials: 'same-origin',
              headers: {
                Authorization: `bearer ${process.env.STRAPI_TOKEN}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }
          );
          if (response.ok) {
            localStorage.setItem('email', email);
            window.location.href = '/request-demo/';
          } else {
            window.alert('Invalid/Registered email, please try again');
          }
        } else {
          // The email domain is associated with a free email service provider
          window.alert('Please enter your valid work email.');
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
      window.alert('Something went wrong. Please try again.');
    }
  };

  let animationContainer = useRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: media,
      rendererSettings: {
        viewBoxSize: '150 150 1250 700',
      },
    });
    return () => anim.destroy();
  }, []);

  useEffect(() => {
    if (email && email.length > 0) setButtonActive(true);
    else setButtonActive(false);
  }, [email]);

  return (
    <>
      <HeaderSection />
      <BannerWrapper id="banner_section">
        <Box {...row}>
          <Box {...contentWrapper}>
            {/* <h1 className="headings-container">
            {titlePart1}
            <mark class="green">{titlePart2}</mark>
            {titlePart3}
          </h1> */}
            {/* <Text {...description} content={bannerDescription} /> */}
            <Box {...buttonWrapper} className="email-signup third-container">
              <input
                type="text"
                placeholder="Business Email"
                className="fill-input"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setShowEmailWarning(false); // Hide the warning message when input changes
                }}
              ></input>
              <Button
                {...fillButton}
                title="Book A Demo"
                className="book-a-demo"
                onClick={handleSubmit}
                style={{
                  backgroundColor: buttonActive ? '#86bc4c' : '#f58634',
                }}
                disabled={buttonActive ? false : true}
              />
              <Button
                className="watch-demo-btn"
                title="Watch A Demo"
                onClick={() =>
                  openVideoModal('https://www.youtube.com/embed/EtLmJdIv4Ac')
                }
              />
            </Box>
            {/* Video Modal */}
            <Modal
              isOpen={videoOpen}
              onRequestClose={closeVideoModal}
              contentLabel="YouTube Video"
              ariaHideApp={false}
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay background color
                  zIndex: 9999,
                },
                content: {
                  width: '80%', // Adjust the width as needed
                  maxWidth: '750px', // Maximum width of the modal
                  height: '450px',
                  margin: 'auto auto', // Center the modal horizontally
                  padding: '20px', // Add some padding to the content
                },
              }}
            >
              {/* Close button */}
              <button
                className="close-button"
                onClick={closeVideoModal}
                style={{ position: 'absolute', top: 10, right: 10 }}
              >
                X
              </button>
              {/* YouTube video iframe */}
              <iframe
                width="100%"
                height="400"
                src={link}
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </Modal>
            {showEmailWarning && ( // Display the warning message if showEmailWarning is true
              <p
                style={{
                  color: 'red',
                  fontSize: '12px',
                  textAlign: 'center', // Center the warning message
                }}
              >
                Please enter your corporate email address only to book a demo!
              </p>
            )}
          </Box>
        </Box>
        {/* <Box {...imageWrapper}>
          <Box {...imageArea}>
            <Fade bottom>
              <div>
                <div
                  ref={animationContainer}
                  className="anim_container fouth-container"
                ></div>
              </div>
            </Fade>
          </Box>
        </Box> */}
        {/* <HomeVideoBanner /> */}
      </BannerWrapper>
      <SubSection />
    </>
  );
};

HomeBannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageArea: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  logoStyle: PropTypes.object,
  fillButton: PropTypes.object,
};

HomeBannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: '40px',
  },
  imageWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    flexBox: true,
    justifyContent: 'center',
  },
  imageArea: {
    width: '100%',
    flexBox: true,
    justifyContent: 'center',
    mb: '60px',
  },
  description: {
    fontSize: ['12px', '12px', '12px', '14px', '14px'],
    color: 'black',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#00865b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  logoStyle: {
    maxWidth: ['70px !important', '100px !important'],
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    colors: 'tertiaryWithBg',
    minWidth: ['auto', '120px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  fillInput: {
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    minWidth: ['auto', '200px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default HomeBannerSection;
