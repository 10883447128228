import React from "react";
import AutomatedSequance from "../../../../common/assets/image/saasModern/HomePageImg/Automated22.png";
import ChartPie from "../../../../common/assets/image/saasModern/HomePageImg/ChartPieSlice.svg";
import AiWriterImg from "../../../../common/assets/image/saasModern/HomePageImg/AiWritingImg.png";
import AiWriterIcon from "../../../../common/assets/image/saasModern/HomePageImg/AIWriterIcon.svg";
import AdvanceAnalaticsIcon from "../../../../common/assets/image/saasModern/HomePageImg/AdvanceAnalaticsIcon.svg";
import SoftLeads from "../../../../common/assets/image/saasModern/HomePageImg/SoftLeadIcon.svg";
import CallDispostion from "../../../../common/assets/image/saasModern/HomePageImg/callDispostionIcon.svg";
import SeamlessIcon from "../../../../common/assets/image/saasModern/HomePageImg/StreamLineIcon.svg";
import AdvanceAnalatics from "../../../../common/assets/image/saasModern/HomePageImg/AdvanceAnalatycsImg.png";
import CallDispositonImg from "../../../../common/assets/image/saasModern/HomePageImg/CallDispostion.png";
import SemlessInterigation from "../../../../common/assets/image/saasModern/HomePageImg/SeamlessIntegration.png";

import {
  BannerWrapper,
  ContainerWrapper,
  SubContainerWrapper,
} from "./SalesEngagement.style";

const SalesEngContainer = () => {
  return (
    <BannerWrapper>
      <h2>Sales Engagement</h2>
      <p>
        Streamline your outreach and maximize results with our <br />
        comprehensive sales engagement features
      </p>
      <ContainerWrapper>
        <div className='frame'>
          <div className='div'>
            <div className='div-2'>
              <div className='div-4'>
                <div className='div-5'>
                  <div className='chart-pie-slice'>
                    <img className='asset' alt='Asset' src={ChartPie} />
                  </div>
                  <div className='text-wrapper'>Automated Sequences</div>
                </div>
                <div className='full-width'>
                  <div className='div-3'>
                    <div className='group-wrapper'>
                      <img
                        className='group'
                        alt='AutomatedSequance'
                        src={AutomatedSequance}
                      />
                    </div>
                  </div>
                </div>
                <p className='p'>
                  Streamline your outreach with pre-built and customizable email
                  sequences that nurture leads and drive conversions.
                </p>
              </div>
            </div>
            <div className='div-2'>
              <div className='div-4'>
                <div className='div-5'>
                  <div className='asset-wrapper'>
                    <img className='asset-2' alt='Asset' src={AiWriterIcon} />
                  </div>
                  <div className='text-wrapper'>AI Writer</div>
                </div>
                <div className='overlap-wrapper'>
                  <div className='overlap'>
                    <div className='overlap-group-wrapper'>
                      <div className='overlap-group'>
                        <img className='img' alt='Group' src={AiWriterImg} />
                      </div>
                    </div>
                  </div>
                </div>
                <p className='p'>
                  Craft personalized, high-converting emails in seconds with the
                  help of our AI-powered writing assistant.
                </p>
              </div>
            </div>
          </div>
          <div className='div'>
            <div className='div-2'>
              <div className='div-4'>
                <div className='div-5'>
                  <div className='chart-pie-slice'>
                    <img
                      className='asset'
                      alt='Asset'
                      src={AdvanceAnalaticsIcon}
                    />
                  </div>
                  <div className='text-wrapper'>Advanced Analytics</div>
                </div>
                <div className='full-width'>
                  <div className='div-3'>
                    <div className='group-wrapper'>
                      <img
                        className='group'
                        alt='AutomatedSequance'
                        src={AdvanceAnalatics}
                      />
                    </div>
                  </div>
                </div>
                <p className='p'>
                  Gain deep insights into your sales activities and identify
                  opportunities for improvement through comprehensive reporting
                  and tracking.
                </p>
              </div>
            </div>
            <div className='div-2'>
              <div className='div-4'>
                <div className='div-5'>
                  <div className='asset-wrapper'>
                    <img className='asset-2' alt='Asset' src={CallDispostion} />
                  </div>
                  <div className='text-wrapper'>Call Disposition</div>
                </div>
                <div className='overlap-wrapper'>
                  <div className='overlap'>
                    <div className='overlap-group-wrapper'>
                      <div className='overlap-group'>
                        <img
                          className='img'
                          alt='Group'
                          src={CallDispositonImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <p className='p'>
                  Log call details and outcomes efficiently, improving team
                  communication and data accuracy.
                </p>
              </div>
            </div>
          </div>
          <div className='div-66'>
            <div className='div-67'>
              <div className='div-4'>
                <div className='div-5'>
                  <div className='chart-pie-slice'>
                    <img className='asset' alt='Asset' src={SeamlessIcon} />
                  </div>
                  <div className='text-wrapper'>Seamless Integration</div>
                </div>
                <div className='full-width'>
                  <div className='div-3'>
                    <div className='group-wrapper'>
                      <img
                        className='group'
                        alt='AutomatedSequance'
                        src={SemlessInterigation}
                      />
                    </div>
                  </div>
                </div>
                <p className='p'>
                  Integrate seamlessly with your existing CRM and other sales
                  tools for a unified workflow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </BannerWrapper>
  );
};
export default SalesEngContainer;
