import styled, { keyframes } from 'styled-components';

// Define keyframes for the fadeIn animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const BannerWrapper = styled.section`
  font-family: 'Poppins', sans-serif;
  padding: 50px 0 0 0;
  max-width: 90%;
  margin: 0 auto;

  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: 99;

  /* dimensions are important, ensure it is tall enough to scroll */
  /* min-height: 80vh; */

  /* background-color: #2b70f5; */
  /* @media (max-width: 575px) {
    padding: 120px 0 0 0;
    width: 50%;
  } */

  /* Apply fadeIn animation to each child element */
  .headings-container,
  .secondheading-container,
  .third-container,
  .btn-container {
    animation: ${fadeIn} 1s ease forwards;
    opacity: 0; /* Start with opacity 0 */
  }
  .headings-container {
    animation-delay: 0.5s;
    font-size: 90px;
    letter-spacing: -0.025em;
    /* margin-bottom: 15px; */
    line-height: 130.5%;
    text-align: center;
    font-weight: 300;
    font-style: normal;
    /* margin-bottom: -20px; */

    @media screen and (max-width: 435px) {
      font-size: 40px;

      text-align: center;
    }
  }

  .secondheading-container {
    animation-delay: 1s;
    letter-spacing: -0.025em;

    margin-top: -50px;
    margin-bottom: -120px;

    text-align: center;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    @media screen and (max-width: 435px) {
      margin-top: 20px;
      font-size: 20px;

      text-align: center;
    }
  }

  .third-container {
    animation-delay: 3s;
  }

  .pipe-spn {
    font-weight: 500;
    > svg {
      margin-top: 0px;
    }
  }

  .btn-container {
    animation-delay: 1.5s;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 0px;
  }

  .btnForSignUp {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 600;
    font-size: 18px;
    border-radius: 50px;
    width: 14rem;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 435px) {
      font-size: 16px;
      /* width: 60%; */
      /* height: 35px; */
    }
    > img {
      width: 30px;
      margin-left: 10px;
    }
  }

  .btnForDemoBook {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    border: 1px solid black;
    font-weight: 600;
    font-size: 18px;
    border-radius: 50px;
    width: 14rem;
    height: 56px;
    cursor: pointer;
    :hover {
      /* background-color: #edcd37; */
      color: black;
      transition: all 0.3s ease 0s;
      border: 2px solid #f58634;
    }

    @media screen and (max-width: 435px) {
      font-size: 16px;
      /* width: 60%;
      height: 35px; */
    }
    > img {
      width: 30px;
      margin-left: 10px;
    }
  }
`;
