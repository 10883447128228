import styled from "styled-components";

export const BannerWrapper = styled.section`
  font-family: "Poppins", sans-serif;
  padding: 80px 0 0 0;
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #000;

    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
    @media screen and (max-width: 1200px) {
      font-size: 42px;
    }

    @media screen and (max-width: 450px) {
      font-size: 42px;
    }
  }

  p {
    margin-top: 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    @media screen and (max-width: 1200px) {
      font-size: 26px;
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }
`;

export const ContainerWrapper = styled.div`
  .frame {
    align-items: flex-start;
    background-color: #eff7ff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    position: relative;
    width: 1272px;
  }

  .frame .div {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 100%;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin: 0;
      /* height: 600px; */
    }
  }

  .frame .div-66 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
    width: 49%;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      /* margin: 0; */
      /* height: 600px; */
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      /* width: unset; */
      margin: 0;
      /* height: 600px; */
    }
  }

  .frame .div-2 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    @media screen and (max-width: 1200px) {
      width: 50%;
      height: auto; /* Adjust height for smaller screens */
    }

    @media screen and (max-width: 450px) {
      width: 30%;
      height: auto; /* Adjust height for smaller screens */
    }
  }

  .frame .div-67 {
    align-items: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    @media screen and (max-width: 1200px) {
      margin-left: 103%;
      width: 102%;
      height: auto;
    }

    @media screen and (max-width: 450px) {
      width: 61%;
      height: auto; /* Adjust height for smaller screens */
    }
  }

  .frame .full-width {
    background-color: #ffffff;
    height: 301px;
    position: relative;
    width: 526px;

    @media screen and (max-width: 1200px) {
      /* width: 50%; */
      /* height: auto; */
    }

    @media screen and (max-width: 450px) {
      width: 60%;
      height: 165px;
    }
  }

  .frame .div-3 {
    height: 392px;
    position: relative;
    top: -91px;
  }

  .frame .group-wrapper {
    height: 392px;
    left: 0;
    position: absolute;
    top: 0;
    width: 526px;
  }

  .frame .group {
    height: 296px;
    left: 0;
    position: absolute;
    top: 92px;
    width: 526px;

    @media screen and (max-width: 1200px) {
      width: 100%;
      height: auto; /* Adjust height for smaller screens */
    }

    @media screen and (max-width: 450px) {
      width: 60%;
      height: auto;
    }
  }

  .frame .images {
    height: 202px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 178px;
    width: 526px;

    @media screen and (max-width: 1200px) {
      width: 50%;
      height: auto; /* Adjust height for smaller screens */
    }

    @media screen and (max-width: 450px) {
      width: 60%;
      height: auto;
    }
  }

  .frame .div-4 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 101%;
  }

  .frame .div-5 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }

  .frame .chart-pie-slice {
    height: 32px;
    position: relative;
    width: 32px;
  }

  .frame .asset {
    height: 27px;
    left: 2px;
    object-fit: cover;
    position: absolute;
    top: 1px;
    width: 29px;
  }

  .frame .text-wrapper {
    color: #212121;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 31.3px;
    margin-top: -0.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    @media screen and (max-width: 1200px) {
      font-size: 28px;
    }

    @media screen and (max-width: 450px) {
      font-size: 26px;
    }
  }

  .frame .p {
    align-self: stretch;
    /* color: #212121cc; */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20.9px;
    position: relative;
    text-align: left;
  }

  .frame .overlap-wrapper {
    background-color: #ffffff;
    height: 301px;
    overflow: hidden;
    position: relative;
    width: 526px;
    @media screen and (max-width: 1200px) {
      /* width: 50%; */
    }

    @media screen and (max-width: 450px) {
      height: 200px;
    }
  }

  .frame .overlap {
    height: 392px;
    position: relative;
    top: -89px;
  }

  .frame .wireframe {
    height: 300px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 89px;
    width: 526px;
  }

  .frame .desktop {
    height: 300px;
    left: 53px;
    object-fit: cover;
    position: absolute;
    top: 89px;
    width: 435px;
  }

  .frame .overlap-group-wrapper {
    height: 392px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 525px;
  }

  .frame .overlap-group {
    height: 298px;
    position: relative;
    top: 92px;
  }

  .frame .img {
    height: 296px;
    left: 0;
    position: absolute;
    top: 0;
    width: 525px;
    @media screen and (max-width: 1200px) {
      width: 100%;
      height: auto; /* Adjust height for smaller screens */
    }

    @media screen and (max-width: 450px) {
      width: 60%;
      height: auto;
    }
  }

  .frame .images-2 {
    height: 279px;
    left: 15px;
    object-fit: cover;
    position: absolute;
    top: 19px;
    width: 503px;
  }

  .frame .asset-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 2px;
    position: relative;
  }

  .frame .asset-2 {
    height: 24px;
    object-fit: cover;
    position: relative;
    width: 27px;
  }

  .frame .full-width-wrapper {
    height: 301px;
    overflow: hidden;
    position: relative;
    width: 526px;
  }

  .frame .div-wrapper {
    height: 392px;
    overflow: hidden;
    position: relative;
    top: -61px;
  }

  .frame .overlap-group-2 {
    height: 409px;
    left: -1px;
    position: relative;
    top: -28px;
    width: 527px;
  }

  .frame .img-wrapper {
    height: 392px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 526px;
  }

  .frame .group-2 {
    height: 268px;
    left: 0;
    position: absolute;
    top: 122px;
    width: 526px;
  }

  .frame .artboard-wrapper {
    height: 294px;
    left: 0;
    position: absolute;
    top: 115px;
    width: 526px;
  }

  .frame .artboard {
    height: 595px;
    overflow: hidden;
    position: relative;
    width: 842px;
  }

  .frame .group-3 {
    height: 29px;
    left: 1366px;
    position: absolute;
    top: -2174px;
    width: 154px;
  }

  .frame .advance-analytics {
    height: 191px;
    left: 21px;
    object-fit: cover;
    position: absolute;
    top: 59px;
    width: 488px;
  }

  .frame .chart-pie-slice-2 {
    height: 30px;
    position: relative;
    width: 30px;
  }

  .frame .text-wrapper-2 {
    color: #212121;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 31.3px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame .full-width-2 {
    height: 301px;
    position: relative;
    width: 526px;
  }

  .frame .image-wrapper {
    background-image: url(./group-5630-3.svg);
    background-size: 100% 100%;
    height: 296px;
    position: relative;
    top: 92px;
    width: 526px;
  }

  .frame .image {
    height: 245px;
    left: 60px;
    object-fit: cover;
    position: absolute;
    top: 37px;
    width: 406px;
  }

  .frame .chart-pie-slice-3 {
    flex: 0 0 auto;
    position: relative;
  }

  .frame .div-6 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 6.6px 1px #0000001a;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    padding: 24px 24px 24px 20px;
    position: relative;
    width: 600px;
  }
`;

export const SubContainerWrapper = styled.div`
  background-color: #f6f6f6;
`;

export default BannerWrapper;
