import React from "react";
import MailIcon from "../../../../common/assets/image/saasModern/HomePageImg/MailIcon.svg";
import PhoneIcon from "../../../../common/assets/image/saasModern/HomePageImg/PhoneIcon.svg";
import Contact from "../../../../common/assets/image/saasModern/HomePageImg/ContactImg.png";
import TechnographicData from "../../../../common/assets/image/saasModern/HomePageImg/TechnoGraphicDataImg.png";
import TelePhoneIcon from "../../../../common/assets/image/saasModern/HomePageImg/TecliphonichData.svg";
import BasicFilter from "../../../../common/assets/image/saasModern/HomePageImg/BasicFilterImg.png";
import AdvanceFilter from "../../../../common/assets/image/saasModern/HomePageImg/AdvanceFilterImg.png";
import OrgIcon from "../../../../common/assets/image/saasModern/HomePageImg/ORGIcon.svg";
import OrgChart from "../../../../common/assets/image/saasModern/HomePageImg/ORGChart.png";
import ChromeExt from "../../../../common/assets/image/saasModern/HomePageImg/ChromeImg.png";

import {
  BannerWrapper,
  ContainerWrapper,
  SubContainerWrapper,
} from "./database.style";

const DataBaseContainer = () => {
  return (
    <BannerWrapper>
      <h2>Database</h2>
      <p>
        Our comprehensive database empowers your B2B
        <br /> outreach efforts with a wealth of information
      </p>
      <ContainerWrapper>
        <div className='frame'>
          <div className='div'>
            <div className='div-2'>
              <div className='div-3'>
                <div className='div-4'>
                  <div className='text-wrapper'>Contacts</div>
                </div>
                <div className='div-5'>
                  <div className='div-6'>
                    <div className='div-7'>
                      <div className='div-8' />
                      <img className='img' alt='Mail' src={MailIcon} />
                    </div>
                    <div className='div-9'>
                      <div className='text-wrapper-2'>600M</div>
                      <div className='text-wrapper-3'>B2B Emails</div>
                    </div>
                  </div>
                  <div className='div-6'>
                    <div className='div-7'>
                      <div className='div-8' />
                      <img
                        className='img'
                        alt='Phone callback'
                        src={PhoneIcon}
                      />
                    </div>
                    <div className='div-9'>
                      <div className='text-wrapper-2'>120M</div>
                      <div className='text-wrapper-3'>Direct Dials</div>
                    </div>
                  </div>
                </div>
                <img className='group' alt='Group' src={Contact} />
                <p className='p'>
                  Connect directly with decision-makers across industries with
                  verified B2B emails and direct dials.
                </p>
              </div>

              <div className='div-10'>
                <div className='div-4'>
                  <div className='text-wrapper'>Technographic Data</div>
                </div>
                <div className='div-11'>
                  <img
                    className='full-width'
                    alt='Full width'
                    src={TechnographicData}
                  />
                  <div className='div-6'>
                    <div className='div-7'>
                      <div className='div-8'>
                        <img
                          className='layer'
                          alt='Layer'
                          src={TelePhoneIcon}
                        />
                      </div>
                    </div>

                    <div className='div-9'>
                      <div className='text-wrapper-2'>15K+</div>
                      <div className='text-wrapper-3'>
                        Total Install Base Data
                      </div>
                    </div>
                  </div>
                </div>
                <p className='p' style={{ top: "16px" }}>
                  Identify companies using specific technologies to tailor your
                  outreach and increase engagement.
                </p>
              </div>
            </div>
            <div className='div-12'>
              <div
                className='text-wrapper left-margin-text'
                // style={{ marginLeft: "450px" }}
              >
                Search &amp; Filtering
              </div>
              <div className='div-13'>
                <div className='div-14'>
                  <div className='text-wrapper-4'>Basic filters</div>
                  <p className='text-wrapper-5'>
                    Refine search with location, industry, and job title.
                  </p>
                  <img
                    className='full-width-2'
                    alt='Basic Filter'
                    src={BasicFilter}
                  />
                  <div className='div-15' />
                </div>
                <div className='div-14'>
                  <div className='text-wrapper-4'>Advanced filters</div>
                  <p className='text-wrapper-5'>
                    Target specific segments with powerful filters.
                  </p>
                  <img
                    className='full-width-3'
                    alt='Advance Filter'
                    src={AdvanceFilter}
                  />
                  <div className='div-15' />
                </div>
              </div>
            </div>
            <div className='div-52'>
              <div className='div-16'>
                <div className='text-wrapper'>Organizational Insights</div>

                <div className='div-17'>
                  <div className='overlap-group-wrapper'>
                    <div className='overlap-group'>
                      <img className='images' alt='Images' src={OrgChart} />
                    </div>
                  </div>
                  <div className='div-6'>
                    <div className='div-7'>
                      <div className='div-8' />
                      <img className='layer-2' alt='Layer' src={OrgIcon} />
                    </div>
                    <div className='div-9'>
                      <div className='text-wrapper-2'>18M+</div>
                      <div className='text-wrapper-3'>Companies Structure</div>
                    </div>
                  </div>
                </div>
                <p className='p' style={{ top: "321px" }}>
                  <b>Org Charts</b> Understand the reporting structure of over
                  18 million companies, making it easier to reach the right
                  people within each organization.
                </p>
              </div>

              <div className='div-16'>
                <div className='text-wrapper'>Chrome Extension</div>

                <div className='div-18' />
                <div className='div-wrapper'>
                  <div className='overlap-group-2'>
                    {/* <img
                      className="group-4"
                      alt="Group"
                      src="group-5630-3.svg"
                    /> */}
                    <img className='images-2' alt='Images' src={ChromeExt} />
                  </div>
                </div>
                <p className='p' style={{ top: "424px" }}>
                  <b>Seamless Integration</b> Access contact information
                  directly from any website or professional social media profile
                  through our convenient Chrome extension.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </BannerWrapper>
  );
};

export default DataBaseContainer;
